<template>
  <div class="buttons">
    <button
      v-if="isActiveMonitoringButton"
      class="button monitoring-icon--active"
    >
      <MonitoringActiveIcon />
    </button>
    <button
      v-else
      @click="$emit('active-monitoring-button')"
      class="button monitoring-icon"
    >
      <MonitoringIcon />
    </button>
  </div>
</template>

<script>
import MonitoringIcon from '@/components/monitoring/assets/Monitoring.vue'
import MonitoringActiveIcon from '@/components/monitoring/assets/MonitoringActive.vue'

export default {
  name: 'MonitoringTool',
  components: {
    MonitoringIcon,
    MonitoringActiveIcon
  },
  props: {
    isActiveMonitoringButton: {
      type: Boolean,
      default: false
    },
    isActiveMonitoringTarget: {
      type: Boolean,
      default: false
    },
    isMonitoringTarget: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  watch: {
    isActiveMonitoringTarget(val) {
      if (val) {
        this.isActive = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.buttons
  display flex
  flex-direction column
  position absolute
  z-index 2
  right 22px
  top 39%
  .button
    height 36px
    width 36px
    display flex
    justify-content center
    align-items center
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
    border-radius 50%
    outline none
  .monitoring-icon
    background #fff
  .monitoring-icon--active
    background #446CA7
</style>
